<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/scitox_logo.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              SciTox
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Register to access SciTox portal
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form @submit.prevent="handleSignup">
            <v-text-field
              v-model="form.name"
              outlined
              label="Name"
              placeholder="your name"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="form.email"
              outlined
              label="Email"
              placeholder="Your email"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="form.phone"
              outlined
              label="Phone"
              placeholder="Your phone number"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="form.password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-checkbox
              hide-details
              class="mt-1"
              v-model="form.agreeTerms"
            >
              <template #label>
                <div class="d-flex align-center flex-wrap">
                  <span class="me-2">I agree to</span><a href="javascript:void(0)">privacy policy &amp; terms</a>
                </div>
              </template>
            </v-checkbox>

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="handleSignup()"
            >
              Sign Up
            </v-btn>
          </v-form>
        </v-card-text>

        <v-alert
          type="error"
          v-if="errorMsg.length > 1"
          dismissible
        > 
          <span v-html="errorMsg"></span>
        </v-alert>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Already have an account?
          </span>
          <router-link :to="{ name:'pages-login' }">
            Sign in instead
          </router-link>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline, mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { signupUser, login } from "../../services/apiCall.js";
import { isValidEmail } from "../../services/utils.js";

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const name = ref('')
    const email = ref('')
    const password = ref('')
    const agreeTerms = ref(false)

    return {
      isPasswordVisible,
      signupUser,
      login,
      errorMsg: '',
      formErrors: [],

      form: {
        name: '',
        email: '',
        countryCode: '91',
        phone: '',
        password: '',
        agreeTerms: false,
      },

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiClose,
      },
    }
  },
  methods: {
    handleSignup() {
      if (this.submitDisabled) return;
      this.submitDisabled = true;
      if (this.validateForm()) {
        console.log('Signup:', this.form);
        this.signupUser({
          'name': this.form.name,
          'email': this.form.email,
          'country_code': this.form.countryCode,
          'phone': this.form.phone,
          'password': this.form.password,
        })
          .then(async (res1) => {
            if (res1.data.status === 0) {
              // user creation successful, call login api to get logged in
              this.login({
                'user_name': this.form.email,
                'password': this.form.password,
              })
                .then(async (res2) => {
                  this.submitDisabled = false;
                  if (res2.data.status === 0) {
                    // navigate to dashboard
                    console.log('Signup successful:', res2);
                    const loggedInUser = res2.data.result;
                    await this.$store.dispatch("adduser", loggedInUser);
                    this.$router.replace("/dashboard");
                  } else {
                    console.log("User login - err1", res2);
                    this.errorMsg = res2.data.error.error_message;
                  }
              }).catch(async (err) => {
                this.submitDisabled = false;
                console.log("User login - err2", err);
                this.errorMsg = err;
              });
            } else {
              this.submitDisabled = false;
              this.errorMsg = res1.data.error.error_message;
            }
          }).catch(async (err) => {
            this.submitDisabled = false;
            console.log("User signup - err2", err);
            this.errorMsg = err;
          });
      } else {
        this.submitDisabled = false;
        let errorMsg = this.formErrors.join("<br/>* ");
        this.errorMsg = "<br/>Please correct the following error(s):<br/>* " + errorMsg;
      }
    },
    validateForm() {
      console.log('Validate');
      this.formErrors = [];

      if (!this.form.name) {
        this.formErrors.push("Name is empty");
      }

      if (!this.form.email) {
        this.formErrors.push("Email is empty");
      }

      if (!this.form.password) {
        this.formErrors.push("Password is empty");
      }

      if (!isValidEmail(this.form.email)) {
        this.formErrors.push("Invalid Email format provided");
      }
      
      if (!this.form.agreeTerms) {
        console.log('Agree:', this.form.agreeTerms);
        this.formErrors.push("Terms and conditions are agreed");
      }

      console.log('Validate:', this.formErrors);
      return this.formErrors.length === 0;
    },
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
